import React, { FormEventHandler, useRef } from 'react';
import { Form, Input } from 'antd';

interface IInput {
  name: string;
  label?: string;
  required?: boolean;
  placeholder?: string;
  onInput?: FormEventHandler<HTMLInputElement> | undefined;
  textArea?: boolean;
  search?: boolean;
  onSearch?: (
    value: string,
    event?:
      | React.ChangeEvent<HTMLInputElement>
      | React.MouseEvent<HTMLElement>
      | React.KeyboardEvent<HTMLInputElement>,
  ) => void;
  type?: string;
  textEditor?: boolean;
  labelCol?: { span: number };
  maxLength?: number;
  readonly?: boolean;
}

const FormInput: React.FC<IInput> = ({
  name,
  label,
  required,
  placeholder,
  onInput,
  textArea,
  search,
  onSearch,
  type,
  labelCol,
  maxLength,
  readonly
}) => {
  const ref = useRef(null);

  const settings = {
    name,
    label,
    valuePropName: 'value',
    hasFeedback: required ? true : undefined,
    rules: required
      ? [
          {
            required: true,
            message: 'Пожалуйста, заполните поле!',
          },
          {
            validator: (_: any, e: any) => {
              if (!ref.current) return Promise.reject('');
              const value: string = (ref.current as any).input?.value.trim();
              if (!value) return Promise.reject('');
              const hasLetter = /[a-zA-Zа-яА-Я]/.test(value);
              const hasDigit = /\d/.test(value);
              if (!hasLetter && !hasDigit) return Promise.reject('');
              return Promise.resolve();
            },
            message: 'Поле должно содержать букву или цифру!',
          },
        ]
      : undefined,
    labelCol,
  };

  return (
    <Form.Item {...settings}>
      {textArea ? (
        <Input.TextArea
          ref={ref}
          autoSize={true}
          placeholder={placeholder}
          maxLength={maxLength}
        />
      ) : search ? (
        <Input.Search
          ref={ref}
          type={type}
          placeholder={placeholder}
          allowClear
          onSearch={onSearch}
          maxLength={maxLength}
        />
      ) : (
        <Input
          ref={ref}
          type={type}
          placeholder={placeholder}
          onInput={onInput}
          maxLength={maxLength}
          readOnly={readonly}
        />
      )}
    </Form.Item>
  );
};

export default FormInput;
