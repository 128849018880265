import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Form, Modal, Upload } from 'antd';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

import styles from './News.module.scss';
import { useHttp, UseHttpI } from '../../../hooks/useHttp';
import { ApiUrl } from '../../../types/ApiUrl';
import NotificationAlert from '../../../components/Notification';
import { statusNewsOptions } from '../../../helpers/newsHelper';
import { transliterate } from '../../../helpers/productHelper';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import {
  SelectOptionHelper,
  SelectOptionUserHelper,
} from '../../../helpers/selectHelper';
import { useActions } from '../../../hooks/useActions';
import { useHttpUploadAntd } from '../../../hooks/useHttpUploadAntd';
import FormInput from '../../../components/FormItems/FormInput';
import FormDatePicker from '../../../components/FormItems/FormDatePicker';
import FormSelect from '../../../components/FormItems/FormSelect';
import TextEditor from '../../../components/Editors/TextEditor';
import { RolesInterceptor } from '../../../components/Interceptors/RolesInterceptor';
import { useValidator, ValidateType } from '../../../hooks/useValidator';
import { useTrimField } from '../../../hooks/useTrimField';
import { ADD_SUBMIT_METHOD, ROLES } from '../../../types/constants';

interface NewsProps {
  titlePage: string;
  submitMethod: string;
}

export const News: React.FC<NewsProps> = ({ titlePage, submitMethod }) => {
  const { geoZones } = useTypedSelector((state) => state.geoZone);
  const { users } = useTypedSelector((state) => state.user);
  const [editor, setEditor] = useState(true);

  const navigate = useNavigate();

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  const { id } = useParams();
  const { request } = useHttp();
  const { requestUpload } = useHttpUploadAntd();

  const { fetchUsersForSelect, fetchGeoZonesForSelect } = useActions();

  const geoZoneOptions = SelectOptionHelper(geoZones);
  const userOptions = SelectOptionUserHelper(users);

  const getData = useCallback(async () => {
    if (id) {
      try {
        const fetchNews = await request({
          url: `${ApiUrl.NEWS}/${id}`,
          method: 'get',
        });

        if (fetchNews.data?.image) {
          setFileList([
            {
              uid: id,
              name: fetchNews.data?.image,
              status: 'done',
              url: `${ApiUrl.FILE}/${fetchNews.data?.image}?dir=news&root=0`,
            },
          ]);
        }

        form.setFieldsValue({
          title: fetchNews.data?.title,
          slug: fetchNews.data?.slug,
          date:
            fetchNews.data?.date !== null
              ? moment(fetchNews.data?.date)
              : undefined,
          shortText: fetchNews.data?.shortText,
          fullText: fetchNews.data?.fullText,
          keywords: fetchNews.data?.keywords,
          description: fetchNews.data?.description,
          metaTitle: fetchNews.data?.metaTitle,
          geoZoneId: fetchNews.data?.geoZoneId,
          userId: fetchNews.data?.userId,
          status: fetchNews.data?.status.toString(),
        });
      } catch (e) {}
    }
  }, [request]);

  const [form] = Form.useForm();

  useEffect(() => {
    fetchUsersForSelect();
    fetchGeoZonesForSelect();
    getData();
    if (!id) {
      form.setFieldsValue({
        status: '0',
      });
    }
  }, [getData]);

  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const handleCancel = () => setPreviewOpen(false);

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const onPreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(file.name);
  };

  const onFinish = async (e: any) => {
    useTrimField(form, null, e);

    const useValidatorParams = {
      value: e.slug,
      listValidateTypes: [ValidateType.slug],
    };
    if (!useValidator(useValidatorParams).includes(true))
      return NotificationAlert('error', 'Некорректный алиас!');
    try {
      if (e.date === undefined) {
        delete e.date;
      } else if (e.date === null) {
        e.date = null;
      } else {
        e.date = e.date.toISOString();
      }

      const requestObject: UseHttpI =
        submitMethod === ADD_SUBMIT_METHOD
          ? {
              url: ApiUrl.NEWS,
              method: 'post',
              body: {
                ...e,
                image: fileList[0]?.response?.name
                  ? fileList[0].response.name
                  : null,
              },
            }
          : {
              url: `${ApiUrl.NEWS}/${id}`,
              method: 'put',
              body: {
                ...e,
                image: fileList[0]?.response?.name
                  ? fileList[0].response.name
                  : fileList[0]?.name
                  ? fileList[0].name
                  : null,
              },
            };

      const response = await request(requestObject);

      switch (response.config.method) {
        case 'post':
          navigate('/content/news/list');
          return NotificationAlert('success', 'Новость добавлена');
        case 'put':
          return NotificationAlert('success', 'Новость обновлена');
        default:
          return NotificationAlert('error', 'Упс');
      }
    } catch (err: any) {
      console.log(err)
      if (err.response.data.statusCode === 403) {
        return NotificationAlert(
          'error',
          'Изображение не добавлено, попробуйте загрузить изображение.',
        );
      } else {
        return NotificationAlert('error', err.response.data?.message || err.message);
      }
    }
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    form.setFieldsValue({
      slug: transliterate(event.target.value.toLowerCase()),
    });
  };

  return (
    <div className={styles.main}>
      <h1>{titlePage}</h1>
      <Form onFinish={onFinish} form={form} labelCol={{ span: 6 }}>
        <RolesInterceptor roles={[ROLES.content]}>
          <FormInput
            name={'title'}
            label={'Заголовок'}
            placeholder={'Введите заголовок'}
            required={true}
            onInput={handleTitleChange}
            labelCol={{ span: 24 }}
            maxLength={250}
          />
          <FormInput
            name={'slug'}
            label={'Алиас'}
            placeholder={'Алиас'}
            labelCol={{ span: 24 }}
            maxLength={150}
          />
          <FormDatePicker
            name={'date'}
            label={'Дата публикации'}
            placeholder={'Дата публикации'}
            value={form.getFieldValue('date')}
            showTime={true}
            labelCol={{ span: 24 }}
          />
          <FormInput
            name={'shortText'}
            label={'Короткий текст'}
            placeholder={'Короткий текст'}
            textArea={true}
            labelCol={{ span: 24 }}
            maxLength={350}
          />
        </RolesInterceptor>
        <RolesInterceptor roles={[ROLES.content, ROLES.seo]}>
          <Form.Item name="fullText">
            {editor ? (
              <>
                <div className="ant-col ant-col-24 ant-form-item-label">
                  <label htmlFor="fullText" title="Полный текст">
                    Полный текст
                  </label>
                </div>
                <TextEditor
                  placeholder={'Полный текст'}
                  text={form.getFieldValue('fullText')}
                  onChange={(content: string) => {
                    form.setFieldsValue({ fullText: content });
                  }}
                />
              </>
            ) : (
              <FormInput
                name={'fullText'}
                label={'Полный текст'}
                placeholder={'Полный текст'}
                labelCol={{ span: 24 }}
                textArea={true}
              />
            )}
          </Form.Item>
          <Button
            type="primary"
            id="editor"
            onClick={() => setEditor(!editor)}
            style={{ margin: '15px 0', backgroundColor: 'black' }}
          >
            {!editor ? 'Показать редактор' : 'Показать html'}
          </Button>
          <Card
            style={{ marginTop: 16, textAlign: 'left' }}
            type="inner"
            title="Данные для поисковой оптимизации"
          >
            <FormInput
              name={'metaTitle'}
              label={'metaTitle'}
              placeholder={'Заголовок'}
              textArea={true}
              labelCol={{ span: 24 }}
              maxLength={50}
            />
            <FormInput
              name={'keywords'}
              label={'metaKeywords'}
              placeholder={'Ключевые слова'}
              textArea={true}
              labelCol={{ span: 24 }}
              maxLength={250}
            />
            <FormInput
              name={'metaDescription'}
              label={'metaDescription'}
              placeholder={'Описание'}
              textArea={true}
              labelCol={{ span: 24 }}
              maxLength={200}
            />
          </Card>
        </RolesInterceptor>
        <RolesInterceptor roles={[ROLES.content]}>
          <FormSelect
            name={'geoZoneId'}
            label={'Геозона'}
            placeholder={'Выберите геозону'}
            options={geoZoneOptions}
            showSearch={true}
            labelCol={{ span: 24 }}
          />
          <FormSelect
            name={'userId'}
            label={'Пользователь'}
            placeholder={'Выберите пользователя'}
            options={userOptions}
            showSearch={true}
            labelCol={{ span: 24 }}
          />
          <FormSelect
            name={'status'}
            label={'Статус'}
            placeholder={'Выберите статус'}
            options={statusNewsOptions}
            labelCol={{ span: 24 }}
          />

          {/*<ImgCrop*/}
          {/*  rotate*/}
          {/*  cropperProps={{ restrictPosition: false }}*/}
          {/*  minZoom={0.5}*/}
          {/*>*/}
          <Upload
            action="/api/news/file"
            listType="picture-card"
            fileList={fileList}
            customRequest={requestUpload}
            onChange={onChange}
            onPreview={onPreview}
            accept=".jpg,.jpeg,.png,.gif"
          >
            {fileList && fileList.length < 1 && '+ Загрузить изображение'}
          </Upload>
        </RolesInterceptor>
        {/*</ImgCrop>*/}
        <Modal
          open={previewOpen}
          title={previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {submitMethod === ADD_SUBMIT_METHOD ? 'Добавить' : 'Обновить'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
