import React from 'react';

import { PaymentReference } from '../PaymentReference';
import { ADD_SUBMIT_METHOD } from '../../../../types/constants';

export const AddPaymentReference: React.FC = () => {
  const titlePage = 'Добавление платежа по ссылке';
  const submitMethod = ADD_SUBMIT_METHOD;

  const props = {
    titlePage: titlePage,
    submitMethod: submitMethod,
  };

  return (
    <>
      <PaymentReference {...props} />
    </>
  );
};
