import React, { useCallback, useEffect } from 'react';
import { Button, Form } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import styles from './Producer.module.scss';
import { transliterate } from '../../../helpers/productHelper';
import { statusProducerOptions } from '../../../helpers/producerHelper';
import { useHttp, UseHttpI } from '../../../hooks/useHttp';
import { ApiUrl } from '../../../types/ApiUrl';
import NotificationAlert from '../../../components/Notification';
import FormInput from '../../../components/FormItems/FormInput';
import FormSelect from '../../../components/FormItems/FormSelect';
import { useTrimField } from '../../../hooks/useTrimField';
import { ADD_SUBMIT_METHOD } from '../../../types/constants';

interface ProducerProps {
  titlePage: string;
  submitMethod: string;
}

export const Producer: React.FC<ProducerProps> = ({
  titlePage,
  submitMethod,
}) => {
  const { id } = useParams();
  const { request } = useHttp();

  const navigate = useNavigate();

  const getData = useCallback(async () => {
    if (id) {
      try {
        const fetchProducer = await request({
          url: `${ApiUrl.PRODUCER}/${id}`,
          method: 'get',
        });

        form.setFieldsValue({
          name: fetchProducer.data?.name,
          shortName: fetchProducer.data?.shortName,
          slug: fetchProducer.data?.slug,
          description: fetchProducer.data?.description,
          shortDescription: fetchProducer.data?.shortDescription,
          metaTitle: fetchProducer.data?.metaTitle,
          metaDescription: fetchProducer.data?.metaDescription,
          metaKeywords: fetchProducer.data?.metaKeywords,
          status: fetchProducer.data?.status.toString(),
          sort: fetchProducer.data?.sort,
          // eslint-disable-next-line camelcase
          id_1c: fetchProducer.data?.id_1c,
        });
      } catch (e) {}
    }
  }, [request]);

  const [form] = Form.useForm();

  useEffect(() => {
    getData();
    if (!id) {
      form.setFieldsValue({
        status: '0',
      });
    }
  }, [getData]);

  const onFinish = async (e: any) => {
    try {
      useTrimField(form, null, e);

      const requestObject: UseHttpI =
        submitMethod === ADD_SUBMIT_METHOD
          ? { url: ApiUrl.PRODUCER, method: 'post', body: e }
          : { url: `${ApiUrl.PRODUCER}/${id}`, method: 'put', body: e };

      const response = await request(requestObject);

      switch (response.config.method) {
        case 'post':
          navigate('/producers/list');
          return NotificationAlert('success', 'Категория добавлена');
        case 'put':
          return NotificationAlert('success', 'Категория обновлена');
        default:
          return NotificationAlert('error', 'Упс');
      }
    } catch (err: any) {
      if (err.response) {
        return err.response.data.map((el: any) => {
          return NotificationAlert('error', el);
        });
      } else {
        return NotificationAlert('error', err.response.data?.message || err.message);
      }
    }
  };
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    form.setFieldsValue({
      slug: transliterate(event.target.value.toLowerCase()),
    });
  };

  return (
    <div className={styles.main}>
      <h1>{titlePage}</h1>
      <Form onFinish={onFinish} form={form} labelCol={{ span: 6 }}>
        <FormInput
          name={'name'}
          label={'Наименование'}
          required={true}
          placeholder={'Наименование производителя'}
          onInput={handleNameChange}
        />
        <FormInput
          name={'shortName'}
          label={'shortName'}
          placeholder={'shortName'}
        />
        <FormInput name={'slug'} label={'Slug'} placeholder={'Slug'} />
        <FormInput
          name={'description'}
          label={'Описание'}
          placeholder={'Описание производителя'}
          textArea={true}
        />
        <FormInput
          name={'shortDescription'}
          label={'Короткое описание'}
          placeholder={'Короткое описание'}
          textArea={true}
        />
        <FormInput
          name={'metaTitle'}
          label={'metaTitle'}
          placeholder={'metaTitle'}
        />
        <FormInput
          name={'metaDescription'}
          label={'metaDescription'}
          placeholder={'metaDescription'}
          textArea={true}
        />
        <FormInput
          name={'metaKeywords'}
          label={'metaKeywords'}
          placeholder={'metaKeywords'}
          textArea={true}
        />
        <FormSelect
          name={'status'}
          label={'Статус'}
          placeholder={'Выберите статус'}
          options={statusProducerOptions}
        />
        <FormInput
          name={'sort'}
          label={'sort (number)'}
          placeholder={'sort'}
          type={'number'}
        />
        <FormInput name={'id_1c'} label={'id_1c'} placeholder={'id_1c'} />
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {submitMethod === ADD_SUBMIT_METHOD ? 'Добавить' : 'Обновить'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
