import React from 'react';

import { MenuItem } from '../MenuItem';
import { ADD_SUBMIT_METHOD } from '../../../../types/constants';

export const AddMenuItem: React.FC = () => {
  const titlePage = 'Добавление пункта меню';
  const submitMethod = ADD_SUBMIT_METHOD;

  const props = {
    titlePage: titlePage,
    submitMethod: submitMethod,
  };

  return (
    <>
      <MenuItem {...props} />
    </>
  );
};
