export enum ApiUrl {
  // PROD_URL = 'http://localhost:8080',
  SERVER_URL = 'http://localhost:5001',

  LOGIN = '/api/auth/admin/login',
  LOGIN_BY_CODE = '/api/auth/admin/login-by-code',
  GET_AUTH_CODE = '/api/auth/admin/get-auth-code',
  REGISTRATION = '/api/auth/admin/registration',
  LOGOUT = '/api/auth/admin/logout',
  REFRESH = '/api/auth/admin/refresh',
  PRODUCT = '/api/product',
  UPDATE_PRODUCT_STATUS = '/api/product/status',
  GET_ALL_PRODUCT_ATTRIBUTES = '/api/product/attributes/getAll',
  TYPE = '/api/product/type',
  PRODUCT_LOG = '/api/product/log',
  ROLE = '/api/roles',
  PRODUCT_SEARCH = '/api/product/search',
  PRODUCER = '/api/producer',
  UPDATE_PRODUCER_STATUS = '/api/producer/status',
  SHOP = '/api/delivery-point',
  UPDATE_SHOP_STATUS = '/api/delivery-point/status',
  CATEGORY = '/api/category',
  UPDATE_CATEGORY_STATUS = '/api/category/status',
  CATEGORY_SELECT = '/api/category/select',
  FILE = '/api/file',
  PRODUCT_SLIDER = '/api/product-slider',
  UPDATE_PRODUCT_SLIDER_GEO_ZONE = '/api/product-slider/geo-zone',
  UPDATE_PRODUCT_SLIDER_SORT = '/api/product-slider/sort',
  SLIDER_GALLERY = '/api/slider-gallery',
  UPDATE_SLIDER_GALLERY_STATUS = '/api/slider-gallery/status',
  STATIC_PAGE = '/api/staticpage',
  GEO_CITY = '/api/geo-city',
  GEO_CITY_SELECT = '/api/geo-city/select',
  UPDATE_GEO_CITY_STATUS = '/api/geo-city/status',
  ATTRIBUTE_GROUP = '/api/attribute/groups',
  GET_ALL_ATTRIBUTE_GROUPS = '/api/attribute/groups/getAll',
  GET_ALL_ATTRIBUTE_GROUPS_FOR_SELECT = '/api/attribute/groups/getAll/select',
  GET_ONE_ATTRIBUTE_GROUP = '/api/attribute/groups/getOne',
  ADD_ATTRIBUTE_OPTION = '/api/attribute/option/add',
  DELETE_ATTRIBUTE_OPTION = '/api/attribute/option/delete',
  ORDER = '/api/store-order',
  ORDER_HISTORY = '/api/store-order/order-history',
  UPDATE_ORDER = '/api/store-order/product/update',
  PRODUCT_ORDER = '/api/store-order/product/getOne',
  DELETE_ORDER_PRODUCT = '/api/store-order/product/delete',
  ADD_PRODUCT_ORDER = '/api/store-order/product/add',
  USER = '/api/user',
  ATTRIBUTE = '/api/attribute',
  USER_SELECT = '/api/user/select',
  UPDATE_DELIVERY_STATUS = '/api/delivery/status',
  DELIVERY = '/api/delivery',
  DELIVERY_SELECT = '/api/delivery/select',
  UPDATE_PAYMENT_STATUS = '/api/payment/status',
  PAYMENT = '/api/payment',
  PAYMENT_SELECT = '/api/payment/select',
  PAYMENT_ONLINE = '/api/payment/payment-online',
  GET_ALL_PAYMENT_ONLINE = '/api/payment/payment-online/getAll',
  GET_ONE_PAYMENT_ONLINE = '/api/payment/payment-online/getOne',
  UPDATE_PAYMENT_ONLINE_STATUS = '/api/payment/payment-online/status',
  PAYMENT_REFERENCE = '/api/payment/payment-reference',
  GET_ALL_PAYMENT_REFERENCE = '/api/payment/payment-reference/getAll',
  GET_ONE_PAYMENT_REFERENCE = '/api/payment/payment-reference/getOne',
  UPDATE_PAYMENT_REFERENCE_STATUS = '/api/payment/payment-reference/status',
  MENU = '/api/menu',
  UPDATE_MENU_STATUS = '/api/menu/status',
  MENU_ITEM = '/api/menu/items',
  MENU_ITEM_SELECT = '/api/menu/items/select/getAll',
  GET_ALL_MENU_ITEMS = '/api/menu/items/getAll',
  GET_ONE_MENU_ITEM = '/api/menu/items/getOne',
  UPDATE_MENU_ITEM_STATUS = '/api/menu/items/status',
  PRODUCT_SLIDER_GROUP = '/api/product-slider-group',
  GEO_ZONE = '/api/geo-zone',
  GEO_ZONE_SELECT = '/api/geo-zone/select',
  CITIES_TREE_SELECT = '/api/geo-zone/tree/select',
  GEO_REGION = '/api/geo-region',
  GEO_REGION_SELECT = '/api/geo-region/select',
  UPDATE_GEO_REGION_STATUS = '/api/geo-region/status',
  NEWS = '/api/news',
  ARTICLES = '/api/articles',
  UPDATE_NEWS_STATUS = '/api/news/status',
  PAGE = '/api/staticpage',
  UPDATE_PAGE_STATUS = '/api/staticpage/status',
  IMAGE = '/api/image',
  UPDATE_IMAGE_STATUS = '/api/image/status',
  UPDATE_IMAGE_GEO_ZONE = '/api/image/geo-zone',
  UPDATE_CACHE_MAIN = '/api/admin/update/cache/main',
  RESET_CACHE_REDIS = '/api/admin/reset/cache',
  SEO_FILES = 'api/file/seo',
  SITEMAP = 'api/file/sitemap',
  CONSTANTS = 'api/constants',
  SERVICES = '/api/services',
  COMMENTS = '/api/product/comments',
  ONE_C_PRICES = '/one-c/prices'
}
