import React, { useEffect, useState } from 'react';

import {
  statusProductOptions,
  transliterate,
} from '../../../../../../helpers/productHelper';
import styles from './MainAttributes.module.scss';
import FormInput from '../../../../../../components/FormItems/FormInput';
import FormSelect from '../../../../../../components/FormItems/FormSelect';
import FormTreeSelect from '../../../../../../components/FormItems/FormTreeSelect';
import { useTypedSelector } from '../../../../../../hooks/useTypedSelector';
import { SelectOptionHelper } from '../../../../../../helpers/selectHelper';
import { FormInstance } from 'antd';
import { UPDATE_SUBMIT_METHOD } from '../../../../../../types/constants';

interface MainAttributesProps {
  producerOptions: any;
  categoryTree: any;
  form: FormInstance;
  submitMethod: string;
  currentCategory: number[] | null;
  setCurrentCategory: (val: number[] | null) => void;
}

export const MainAttributes: React.FC<MainAttributesProps> = ({
  producerOptions,
  categoryTree,
  form,
  submitMethod,
  currentCategory,
  setCurrentCategory,
}) => {
  const { types } = useTypedSelector((state) => state.type);

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    form.setFieldsValue({
      productSlug: transliterate(event.target.value.toLowerCase()),
    });
  };

  useEffect(() => {
    if (!currentCategory) form.setFieldValue('productType', undefined);
  }, [currentCategory]);

  return (
    <div className={styles.main}>
      <FormInput
        name={'productInputTitle'}
        label={'Название'}
        placeholder={'Название'}
        required={true}
        onInput={handleTitleChange}
      />
      <FormInput
        name={'productSku'}
        label={'Sku (number)'}
        placeholder={'Sku'}
        type={'number'}
        maxLength={25}
      />
      <FormInput name={'productSlug'} label={'Slug'} placeholder={'Slug'} />
      <FormInput
        name={'productAveragePrice'}
        label={'Цена (number)'}
        placeholder={'Средняя розничная цена'}
        type={'number'}
        maxLength={200}
      />
      <FormInput
        name={'productDescription'}
        label={'Описание'}
        placeholder={'Описание'}
        textArea={true}
      />
      <FormInput
        name={'productShortDescription'}
        label={'Короткое описание'}
        placeholder={'Короткое описание'}
        textArea={true}
      />
      <FormSelect
        name={'productStatus'}
        label={'Статус'}
        placeholder={'Выберите статус'}
        showSearch={true}
        options={statusProductOptions}
      />
      <FormSelect
        name={'productProducer'}
        label={'Производитель'}
        placeholder={'Выберите производителя'}
        showSearch={true}
        options={producerOptions}
      />
      <FormTreeSelect
        name={'productCategory'}
        label={'Категория'}
        placeholder={'Выберите категорию'}
        showSearch={true}
        multiple={true}
        required={submitMethod === UPDATE_SUBMIT_METHOD}
        treeData={categoryTree}
        onChange={(cat) => setCurrentCategory(cat)}
      />
      <FormSelect
        name={'productType'}
        label={'Тип товара'}
        placeholder={'Выберите тип'}
        showSearch={true}
        options={
          currentCategory
            ? SelectOptionHelper(
                types.filter((type) =>
                  currentCategory.includes(type.categoryId),
                ),
              )
            : []
        }
        required={true}
        disabled={!Boolean(currentCategory) || currentCategory?.length === 0}
      />
    </div>
  );
};
