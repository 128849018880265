import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import styles from './Articles.module.scss';
import { useHttp, UseHttpI } from '../../../hooks/useHttp';
import { ApiUrl } from '../../../types/ApiUrl';
import NotificationAlert from '../../../components/Notification';
import { useActions } from '../../../hooks/useActions';
import FormInput from '../../../components/FormItems/FormInput';
import TextEditor from '../../../components/Editors/TextEditor';
import { RolesInterceptor } from '../../../components/Interceptors/RolesInterceptor';
import { createTree } from '../../../helpers/selectHelper';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import FormTreeSelect from '../../../components/FormItems/FormTreeSelect';
import FormSelect from '../../../components/FormItems/FormSelect';
import { statusNewsOptions } from '../../../helpers/newsHelper';
import { useTrimField } from '../../../hooks/useTrimField';
import { ADD_SUBMIT_METHOD, ROLES } from '../../../types/constants';

interface NewsProps {
  titlePage: string;
  submitMethod: string;
}

export const Articles: React.FC<NewsProps> = ({ titlePage, submitMethod }) => {
  const [editor, setEditor] = useState(true);

  const navigate = useNavigate();
  const { categories } = useTypedSelector((state) => state.category);
  const categoryTree = createTree(categories);
  const { fetchCategoriesForSelect } = useActions();

  const { id } = useParams();
  const { request } = useHttp();

  const getData = useCallback(async () => {
    if (id) {
      try {
        const fetchNews = await request({
          url: `${ApiUrl.ARTICLES}/${id}`,
          method: 'get',
        });

        form.setFieldsValue({
          title: fetchNews.data?.title,
          content: fetchNews.data?.content,
          status: statusNewsOptions.find(
            (item) => +item.value === fetchNews.data?.status,
          ),
          categoryId: fetchNews.data?.categoryId,
        });
      } catch (e) {}
    }
  }, [request]);

  const [form] = Form.useForm();

  useEffect(() => {
    fetchCategoriesForSelect();
    getData();
    if (!id) {
      form.setFieldsValue({
        status: statusNewsOptions[0],
      });
    }
  }, []);

  const onFinish = async (e: any) => {
    try {
      useTrimField(form, null, e);

      const requestObject: UseHttpI =
        submitMethod === ADD_SUBMIT_METHOD
          ? {
              url: ApiUrl.ARTICLES,
              method: 'post',
              body: {
                ...e,
                status: +e.status || +e.status.value,
              },
            }
          : {
              url: `${ApiUrl.ARTICLES}/${id}`,
              method: 'put',
              body: {
                ...e,
                status: +e.status || +e.status.value,
              },
            };

      const response = await request(requestObject);

      switch (response.config.method) {
        case 'post':
          navigate('/content/articles/list');
          return NotificationAlert('success', 'Статья добавлена');
        case 'put':
          return NotificationAlert('success', 'Статья обновлена');
        default:
          return NotificationAlert('error', 'Упс');
      }
    } catch (err: any) {
      return NotificationAlert(
        'error',
        err.response.data?.message || err.message,
      );
    }
  };

  return (
    <div className={styles.main}>
      <h1>{titlePage}</h1>
      <Form onFinish={onFinish} form={form} labelCol={{ span: 6 }}>
        <RolesInterceptor roles={[ROLES.content]}>
          <FormInput
            name={'title'}
            label={'Заголовок'}
            placeholder={'Введите заголовок'}
            required={true}
            labelCol={{ span: 24 }}
            maxLength={100}
          />
          <FormSelect
            name={'status'}
            label={'Статус'}
            placeholder={'Выберите статус'}
            options={statusNewsOptions}
            labelCol={{ span: 24 }}
          />
          <FormTreeSelect
            required={true}
            showSearch={true}
            name={'categoryId'}
            label={'Категория'}
            placeholder={'Выберите категорию'}
            treeData={categoryTree}
            labelCol={{ span: 24 }}
          />
          <Form.Item name="content">
            {editor ? (
              <>
                <div className="ant-col ant-col-24 ant-form-item-label">
                  <label htmlFor="content" title="Полный текст">
                    Полный текст
                  </label>
                </div>
                <TextEditor
                  placeholder={'Полный текст'}
                  text={form.getFieldValue('content')}
                  onChange={(content: string) => {
                    form.setFieldsValue({ content: content });
                  }}
                />
              </>
            ) : (
              <FormInput
                name={'content'}
                label={'Полный текст'}
                placeholder={'Полный текст'}
                labelCol={{ span: 24 }}
                textArea={true}
              />
            )}
          </Form.Item>
          <Button
            type="primary"
            id="editor"
            onClick={() => setEditor(!editor)}
            style={{ margin: '15px 0', backgroundColor: 'black' }}
          >
            {!editor ? 'Показать редактор' : 'Показать html'}
          </Button>
        </RolesInterceptor>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {submitMethod === ADD_SUBMIT_METHOD ? 'Добавить' : 'Обновить'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
