import React from 'react';

import { ProductSliderGroup } from '../ProductSliderGroup';
import { ADD_SUBMIT_METHOD } from '../../../../types/constants';

export const AddProductSliderGroup: React.FC = () => {
  const titlePage = 'Добавление ленты';
  const submitMethod = ADD_SUBMIT_METHOD;

  const props = {
    titlePage: titlePage,
    submitMethod: submitMethod,
  };

  return (
    <>
      <ProductSliderGroup {...props} />
    </>
  );
};
