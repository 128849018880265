import React, { useCallback, useEffect } from 'react';
import { Button, Form } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import styles from './Menu.module.scss';
import { useHttp, UseHttpI } from '../../../hooks/useHttp';
import { ApiUrl } from '../../../types/ApiUrl';
import NotificationAlert from '../../../components/Notification';
import { statusMenuOptions } from '../../../helpers/menuHelper';
import FormInput from '../../../components/FormItems/FormInput';
import FormSelect from '../../../components/FormItems/FormSelect';
import { useTrimField } from '../../../hooks/useTrimField';
import { ADD_SUBMIT_METHOD } from '../../../types/constants';

interface MenuProps {
  titlePage: string;
  submitMethod: string;
}

export const Menu: React.FC<MenuProps> = ({ titlePage, submitMethod }) => {
  const { id } = useParams();
  const { request } = useHttp();

  const navigate = useNavigate();

  const getData = useCallback(async () => {
    if (id) {
      try {
        const fetchMenu = await request({
          url: `${ApiUrl.MENU}/${id}`,
          method: 'get',
        });

        form.setFieldsValue({
          name: fetchMenu.data?.name,
          code: fetchMenu.data?.code,
          description: fetchMenu.data?.description,
          status: fetchMenu.data?.status.toString(),
        });
      } catch (e) {}
    }
  }, [request]);

  const [form] = Form.useForm();

  useEffect(() => {
    getData();
    if (!id) {
      form.setFieldsValue({
        status: '0',
      });
    }
  }, [getData]);

  const onFinish = async (e: any) => {
    try {
      useTrimField(form, null, e);

      const requestObject: UseHttpI =
        submitMethod === ADD_SUBMIT_METHOD
          ? { url: ApiUrl.MENU, method: 'post', body: e }
          : { url: `${ApiUrl.MENU}/${id}`, method: 'put', body: e };

      const response = await request(requestObject);

      switch (response.config.method) {
        case 'post':
          navigate('/menu/menu/list');
          return NotificationAlert('success', 'Меню добавлено');
        case 'put':
          return NotificationAlert('success', 'Меню обновлено');
        default:
          return NotificationAlert('error', 'Упс');
      }
    } catch (err: any) {
      if (err.response) {
        return err.response.data.map((el: any) => {
          return NotificationAlert('error', el);
        });
      } else {
        return NotificationAlert('error', err.response.data?.message || err.message);
      }
    }
  };

  return (
    <div className={styles.main}>
      <h1>{titlePage}</h1>
      <Form onFinish={onFinish} form={form} labelCol={{ span: 6 }}>
        <FormInput
          name={'name'}
          label={'Название'}
          placeholder={'Название меню'}
          required={true}
          labelCol={{ span: 24 }}

        />
        <FormInput
          name={'code'}
          label={'Уникальный код'}
          placeholder={'Уникальный код'}
          required={true}
          labelCol={{ span: 24 }}
          maxLength={100}
        />
        <FormInput
          name={'description'}
          label={'Описание'}
          placeholder={'Описание'}
          required={true}
          labelCol={{ span: 24 }}
          textArea={true}
        />
        <FormSelect
          name={'status'}
          label={'Статус'}
          placeholder={'Выберите статус'}
          options={statusMenuOptions}
          labelCol={{ span: 24 }}
        />
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {submitMethod === ADD_SUBMIT_METHOD ? 'Добавить' : 'Обновить'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
