import React, { useCallback, useEffect } from 'react';
import { Button, Form, Input, Tabs } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import styles from './PaymentOnline.module.scss';
import { useHttp, UseHttpI } from '../../../hooks/useHttp';
import { ApiUrl } from '../../../types/ApiUrl';
import NotificationAlert from '../../../components/Notification';
import {
  entityPaymentOnlineOptions,
  statusPaymentOnlineOptions,
} from '../../../helpers/paymentOnlineHelper';
import {
  SelectOptionHelper,
  SelectOptionUserHelper,
} from '../../../helpers/selectHelper';
import { useActions } from '../../../hooks/useActions';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import FormSelect from '../../../components/FormItems/FormSelect';
import FormInput from '../../../components/FormItems/FormInput';
import { ADD_SUBMIT_METHOD } from '../../../types/constants';

interface PaymentOnlineProps {
  titlePage: string;
  submitMethod: string;
}

export const PaymentOnline: React.FC<PaymentOnlineProps> = ({
  titlePage,
  submitMethod,
}) => {
  const { payments } = useTypedSelector((state) => state.payment);
  const { users } = useTypedSelector((state) => state.user);

  const { id } = useParams();
  const { request } = useHttp();

  const navigate = useNavigate();

  const { fetchPaymentsForSelect, fetchUsersForSelect } = useActions();

  const paymentsOptions = SelectOptionHelper(payments);
  const usersOptions = SelectOptionUserHelper(users);

  const [form] = Form.useForm();

  const getData = useCallback(async () => {
    if (id) {
      try {
        const fetchPaymentOnline = await request({
          url: `${ApiUrl.GET_ONE_PAYMENT_ONLINE}/${id}`,
          method: 'get',
        });

        form.setFieldsValue({
          paymentSystemId: fetchPaymentOnline.data?.paymentSystemId,
          paymentSystemOrderId: fetchPaymentOnline.data?.paymentSystemOrderId,
          entity: fetchPaymentOnline.data?.entity,
          entityId: fetchPaymentOnline.data?.entityId,
          userId: fetchPaymentOnline.data?.userId,
          paymentStatusId: fetchPaymentOnline.data?.paymentStatusId,
          status: fetchPaymentOnline.data?.status.toString(),
          phone: fetchPaymentOnline.data?.phone,
          email: fetchPaymentOnline.data?.email,
          sum: fetchPaymentOnline.data?.sum,
          kkmId: fetchPaymentOnline.data?.kkmId,
          kkmStatus: fetchPaymentOnline.data?.kkmStatus,
          numberOnec: fetchPaymentOnline.data?.numberOnec,
          idOnec: fetchPaymentOnline.data?.idOnec,
          paymentDate: fetchPaymentOnline.data?.paymentDate
            ? new Date(fetchPaymentOnline.data?.paymentDate).toLocaleString()
            : 'Не оплачен',
        });
      } catch (e) {}
    }
  }, [request]);

  useEffect(() => {
    fetchUsersForSelect();
    fetchPaymentsForSelect('all');
    getData();
    if (!id) {
      form.setFieldsValue({
        status: '0',
      });
    }
  }, []);

  const onFinish = async (e: any) => {
    try {
      const requestObject: UseHttpI =
        submitMethod === ADD_SUBMIT_METHOD
          ? { url: ApiUrl.PAYMENT_ONLINE, method: 'post', body: e }
          : { url: `${ApiUrl.PAYMENT_ONLINE}/${id}`, method: 'put', body: e };

      const response = await request(requestObject);

      switch (response.config.method) {
        case 'post':
          navigate('/services/payment/payment-online/list');
          return NotificationAlert('success', 'Онлайн платёж добавлен');
        case 'put':
          return NotificationAlert('success', 'Онлайн платёж обновлен');
        default:
          return NotificationAlert('error', 'Упс');
      }
    } catch (err: any) {
      if (err.response) {
        return err.response.data.map((el: any) => {
          return NotificationAlert('error', el);
        });
      } else {
        return NotificationAlert('error', err.response.data?.message || err.message);
      }
    }
  };

  const checkKkmStatus = async () => {
    const kkmId = form.getFieldValue('kkmId');
    if (!kkmId) return NotificationAlert('error', 'Отсутствует id чека');
    const response = await request({
      url: ApiUrl.PAYMENT + '/online/check/' + kkmId,
      method: 'get',
    });
    if (response.data?.operation?.status === 'complete')
      NotificationAlert('success', 'Чек успешно отправлен');
    else NotificationAlert('error', response.data?.operation?.message);
    form.setFieldValue('kkmStatus', response.data?.operation?.status_int);
  };

  const upload = async () => {
    await request({
      url: ApiUrl.PAYMENT + '/online/send/' + id,
      method: 'get',
    });
    return NotificationAlert('success', 'Чек отправлен. Проверьте статус');
  }

  const items = [
    {
      label: 'ОБЩЕЕ',
      key: '1',
      children: (
        <>
          <FormSelect
            name={'paymentSystemId'}
            label={'Способ оплаты'}
            placeholder={'Способ оплаты'}
            options={paymentsOptions}
            labelCol={{ span: 24 }}
          />
          <FormInput
            name={'paymentSystemOrderId'}
            label={'Id заказа в платежной системе'}
            placeholder={'Id заказа в платежной системе'}
            labelCol={{ span: 24 }}
          />
          <FormSelect
            name={'entity'}
            label={'Объект'}
            placeholder={'Объект'}
            required={true}
            options={entityPaymentOnlineOptions}
            labelCol={{ span: 24 }}
          />
          <FormInput
            name={'entityId'}
            label={'Номер заказа/кредита (number)'}
            placeholder={'Номер заказа/кредита'}
            type={'number'}
            labelCol={{ span: 24 }}
          />
          <FormSelect
            name={'userId'}
            label={'Пользователь'}
            placeholder={'Выберите пользователя'}
            options={usersOptions}
            labelCol={{ span: 24 }}
          />
          <FormInput
            name={'paymentStatusId'}
            label={'Статус в платежной системе (number)'}
            placeholder={'Статус в платежной системе'}
            type={'number'}
            labelCol={{ span: 24 }}
          />
          <FormSelect
            name={'status'}
            label={'Статус'}
            placeholder={'Выберите статус'}
            options={statusPaymentOnlineOptions}
            labelCol={{ span: 24 }}
          />
          <FormInput
            name={'phone'}
            label={'Телефон'}
            placeholder={'Телефон'}
            labelCol={{ span: 24 }}
          />
          <FormInput
            name={'email'}
            label={'E-mail'}
            placeholder={'E-mail'}
            labelCol={{ span: 24 }}
          />
          <FormInput
            name={'sum'}
            label={'Сумма (number)'}
            placeholder={'Сумма'}
            type={'number'}
            labelCol={{ span: 24 }}
          />
          <Form.Item
            name={'paymentDate'}
            label={'Дата оплаты'}
            labelCol={{ span: 24 }}
          >
            <Input
              name={'paymentDate'}
              placeholder={'Дата оплаты'}
              readOnly={true}
            />
          </Form.Item>
          <FormInput
            name={'numberOnec'}
            label={'Номер 1С'}
            placeholder={'Номер 1С'}
            labelCol={{ span: 24 }}
          />
          <FormInput
            name={'idOnec'}
            label={'Id 1С'}
            placeholder={'Id 1С'}
            labelCol={{ span: 24 }}
          />
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {submitMethod === ADD_SUBMIT_METHOD ? 'Добавить' : 'Обновить'}
            </Button>
          </Form.Item>
        </>
      ),
      method: ['post', 'put'],
    },
    {
      label: 'ЧЕК ОФД',
      key: '2',
      children: (
        <>
          <FormInput
            name={'kkmId'}
            label={'Id чека'}
            placeholder={'Id чека'}
            labelCol={{ span: 24 }}
            readonly={true}
          />
          <div className={styles.checkStatus}>
            <FormInput
              name={'kkmStatus'}
              label={'Статус чека (number)'}
              placeholder={'Статус чека'}
              type={'number'}
              labelCol={{ span: 24 }}
              readonly={true}
            />
            <Button type="primary" onClick={checkKkmStatus}>
              Проверить
            </Button>
          </div>
          <Button type="primary" onClick={upload}>Выгрузить</Button>
        </>
      ),
      method: ['put'],
    },
  ];

  const filterItem = items.filter((item) => {
    const method = submitMethod === ADD_SUBMIT_METHOD ? 'post' : 'put';
    return item.method.includes(method);
  });

  return (
    <div className={styles.main}>
      <h1>{titlePage}</h1>
      <Form onFinish={onFinish} form={form} labelCol={{ span: 6 }}>
        <Tabs defaultActiveKey="1" items={filterItem} />
      </Form>
    </div>
  );
};
