import React from 'react';

import { Types } from '../Types';
import { ADD_SUBMIT_METHOD } from '../../../../types/constants';

export const AddTypes: React.FC = () => {
  const titlePage = 'Добавление типа';
  const submitMethod = ADD_SUBMIT_METHOD;

  const props = {
    titlePage: titlePage,
    submitMethod: submitMethod,
  };

  return (
    <>
      <Types {...props} />
    </>
  );
};
