import React from 'react';

import { Payment } from '../Payment';
import { ADD_SUBMIT_METHOD } from '../../../../types/constants';

export const AddPayment: React.FC = () => {
  const titlePage = 'Добавление способа';
  const submitMethod = ADD_SUBMIT_METHOD;

  const props = {
    titlePage: titlePage,
    submitMethod: submitMethod,
  };

  return (
    <>
      <Payment {...props} />
    </>
  );
};
