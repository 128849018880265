import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Form } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import styles from './Page.module.scss';
import { useHttp, UseHttpI } from '../../../hooks/useHttp';
import { ApiUrl } from '../../../types/ApiUrl';
import NotificationAlert from '../../../components/Notification';
import { transliterate } from '../../../helpers/productHelper';
import { statusPageOptions } from '../../../helpers/pageHelper';
import FormInput from '../../../components/FormItems/FormInput';
import FormSelect from '../../../components/FormItems/FormSelect';
import TextEditor from '../../../components/Editors/TextEditor';
import { RolesInterceptor } from '../../../components/Interceptors/RolesInterceptor';
import { useTrimField } from '../../../hooks/useTrimField';
import { ADD_SUBMIT_METHOD, ROLES } from '../../../types/constants';

interface PageProps {
  titlePage: string;
  submitMethod: string;
}

export const Page: React.FC<PageProps> = ({ titlePage, submitMethod }) => {
  const { id } = useParams();
  const { request } = useHttp();
  const [editor, setEditor] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const getData = useCallback(async () => {
    if (id) {
      try {
        const fetchPage = await request({
          url: `${ApiUrl.PAGE}/${id}`,
          method: 'get',
        });

        form.setFieldsValue({
          title: fetchPage.data?.title,
          url: fetchPage.data?.url,
          description: fetchPage.data?.description,
          keywords: fetchPage.data?.keywords,
          metaTitle: fetchPage.data?.metaTitle,
          content: fetchPage.data?.content,
          status: fetchPage.data?.status.toString(),
        });
      } catch (e) {}
    }
  }, [request]);

  const [form] = Form.useForm();

  useEffect(() => {
    getData();
    if (!id) {
      form.setFieldsValue({
        status: '0',
      });
    }
  }, [getData]);

  const onFinish = async (e: any) => {
    try {
      useTrimField(form, null, e);

      setLoading(true);
      const requestObject: UseHttpI =
        submitMethod === ADD_SUBMIT_METHOD
          ? { url: ApiUrl.PAGE, method: 'post', body: e }
          : { url: `${ApiUrl.PAGE}/${id}`, method: 'put', body: e };

      const response = await request(requestObject);
      setLoading(false);
      switch (response.config.method) {
        case 'post':
          navigate('/content/pages/list');
          return NotificationAlert('success', 'Страница добавлена');
        case 'put':
          return NotificationAlert('success', 'Страница обновлена');
        default:
          return NotificationAlert('error', 'Упс');
      }
    } catch (err: any) {
      if (err.response) {
        return err.response.data.map((el: any) => {
          return NotificationAlert('error', el);
        });
      } else {
        return NotificationAlert('error', err.response.data?.message || err.message);
      }
    }
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    form.setFieldsValue({
      url: transliterate(event.target.value.toLowerCase()),
    });
  };

  return (
    <div className={styles.main}>
      <h1>{titlePage}</h1>
      <Form onFinish={onFinish} form={form} labelCol={{ span: 6 }}>
        <RolesInterceptor roles={[ROLES.content]}>
          <FormInput
            name={'title'}
            label={'Заголовок'}
            placeholder={'Введите заголовок'}
            required={true}
            onInput={handleTitleChange}
            labelCol={{ span: 24 }}
            maxLength={100}
          />
          <FormInput
            name={'url'}
            label={'URL'}
            placeholder={'url'}
            labelCol={{ span: 24 }}
            maxLength={100}
          />
          <FormSelect
            name={'status'}
            label={'Статус'}
            placeholder={'Выберите статус'}
            options={statusPageOptions}
            labelCol={{ span: 24 }}
          />
        </RolesInterceptor>
        <RolesInterceptor roles={[ROLES.content, ROLES.seo]}>
          <Card
            style={{ marginTop: 16, textAlign: 'left' }}
            type="inner"
            title="Данные для поисковой оптимизации"
          >
            <FormInput
              name={'metaTitle'}
              label={'Заголовок'}
              placeholder={'Заголовок'}
              textArea={true}
              labelCol={{ span: 24 }}
              maxLength={50}
            />
            <FormInput
              name={'keywords'}
              label={'metaKeywords'}
              placeholder={'Ключевые слова'}
              textArea={true}
              labelCol={{ span: 24 }}
              maxLength={250}
            />
            <FormInput
              name={'metaDescription'}
              label={'Описание'}
              placeholder={'Описание'}
              textArea={true}
              labelCol={{ span: 24 }}
              maxLength={200}
            />
          </Card>
          <Form.Item name={'content'}>
            {editor ? (
              <>
                <div className="ant-col ant-col-24 ant-form-item-label">
                  <label htmlFor="content" title="Контент страницы">
                    Контент страницы
                  </label>
                </div>
                <TextEditor
                  placeholder={'Контент страницы'}
                  text={form.getFieldValue('content')}
                  onChange={(content: string) => {
                    form.setFieldsValue({
                      content: content,
                    });
                  }}
                />
              </>
            ) : (
              <FormInput
                name={'content'}
                label={'Контент страницы'}
                placeholder={'Контент страницы'}
                labelCol={{ span: 24 }}
                textArea={true}
              />
            )}
          </Form.Item>
          <Button
            id="editor"
            type="primary"
            onClick={() => setEditor(!editor)}
            style={{ marginBottom: 16, backgroundColor: 'black' }}
          >
            {!editor ? 'Показать редактор' : 'Показать html'}
          </Button>
        </RolesInterceptor>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            {submitMethod === ADD_SUBMIT_METHOD ? 'Добавить' : 'Обновить'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
