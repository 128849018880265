import React from 'react';

import { GeoCity } from '../GeoCity';
import { ADD_SUBMIT_METHOD } from '../../../../types/constants';

export const AddGeoCity: React.FC = () => {
  const titlePage = 'Добавление города';
  const submitMethod = ADD_SUBMIT_METHOD;

  const props = {
    titlePage: titlePage,
    submitMethod: submitMethod,
  };

  return (
    <>
      <GeoCity {...props} />
    </>
  );
};
