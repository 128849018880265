import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import styles from './Delivery.module.scss';
import { useHttp, UseHttpI } from '../../../hooks/useHttp';
import { IDelivery } from '../../../types/entities/Delivery';
import { ApiUrl } from '../../../types/ApiUrl';
import { statusDeliveryOptions } from '../../../helpers/deliveryHelper';
import NotificationAlert from '../../../components/Notification';
import { SelectOptionHelper } from '../../../helpers/selectHelper';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useActions } from '../../../hooks/useActions';
import FormSelect from '../../../components/FormItems/FormSelect';
import FormInput from '../../../components/FormItems/FormInput';
import FormCheckbox from '../../../components/FormItems/FormCheckbox';
import { useTrimField } from '../../../hooks/useTrimField';
import { ADD_SUBMIT_METHOD } from '../../../types/constants';

interface DeliveryProps {
  titlePage: string;
  submitMethod: string;
}

export const Delivery: React.FC<DeliveryProps> = ({
  titlePage,
  submitMethod,
}) => {
  const { id } = useParams();
  const { request } = useHttp();
  const [delivery, setDelivery] = useState<IDelivery>();
  const { payments } = useTypedSelector((state) => state.payment);

  const navigate = useNavigate();

  const paymentsOptions = SelectOptionHelper(payments);

  const { fetchPaymentsForSelect } = useActions();

  const [form] = Form.useForm();

  const getData = useCallback(async () => {
    if (id) {
      try {
        const fetchDelivery = await request({
          url: `${ApiUrl.DELIVERY}/${id}`,
          method: 'get',
        });
        setDelivery(fetchDelivery.data);

        const paymentOption = fetchDelivery.data.payments.map((item: any) => {
          return item.id;
        });
        form.setFieldsValue({
          name: fetchDelivery.data?.name,
          description: fetchDelivery.data?.description,
          price: fetchDelivery.data?.price,
          freeFrom: fetchDelivery.data?.freeFrom,
          // position: fetchDelivery.data?.position,
          status: fetchDelivery.data?.status.toString(),
          separatePayment: Boolean(fetchDelivery.data?.separatePayment),
          payments: paymentOption,
          // serviceName: fetchDelivery.data?.serviceName,
          // geoZoneId: fetchDelivery.data?.geoZoneId,
          // onecCode: fetchDelivery.data?.onecCode,
        });
      } catch (e) {}
    }
  }, [request]);

  useEffect(() => {
    fetchPaymentsForSelect('active');
    getData();
    if (!id) {
      form.setFieldsValue({
        status: '0',
      });
    }
  }, [getData]);

  const onFinish = async (e: any) => {
    try {
      useTrimField(form, null, e);

      const requestObject: UseHttpI =
        submitMethod === ADD_SUBMIT_METHOD
          ? { url: ApiUrl.DELIVERY, method: 'post', body: e }
          : { url: `${ApiUrl.DELIVERY}/${id}`, method: 'put', body: e };

      const response = await request(requestObject);

      switch (response.config.method) {
        case 'post':
          navigate('/services/deliveries/list');
          return NotificationAlert('success', 'Способ доставки добавлен');
        case 'put':
          return NotificationAlert('success', 'Способ доставки обновлен');
        default:
          return NotificationAlert('error', 'Упс');
      }
    } catch (err: any) {
      if (err.response) {
        return err.response.data.map((el: any) => {
          return NotificationAlert('error', el);
        });
      } else {
        return NotificationAlert('error', err.response.data?.message || err.message);
      }
    }
  };

  return (
    <div className={styles.main}>
      <h1>{titlePage}</h1>
      <Form onFinish={onFinish} form={form} labelCol={{ span: 6 }}>
        <FormSelect
          name={'status'}
          label={'Статус'}
          required={true}
          placeholder={'Выберите статус'}
          options={statusDeliveryOptions}
        />
        <FormInput
          name={'name'}
          label={'Название'}
          placeholder={'Способ доставки'}
          required={true}
        />
        <FormInput
          name={'price'}
          label={'Цена'}
          placeholder={'Цена'}
          required={true}
          type={'number'}
        />
        <FormInput
          name={'freeFrom'}
          label={'Бесплатна от'}
          placeholder={'Бесплатна от'}
          type={'number'}
        />
        <FormCheckbox
          name={'separatePayment'}
          text={'Оплачивается отдельно'}
          checked={Boolean(delivery?.separatePayment)}
        />
        <FormInput
          name={'description'}
          label={'Описание'}
          placeholder={'Описание способа'}
          textArea={true}
        />
        <FormSelect
          name={'payments'}
          label={'Способы оплаты'}
          placeholder={'Выберите способ оплаты'}
          showSearch={true}
          mode={'multiple'}
          options={paymentsOptions}
        />
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {submitMethod === ADD_SUBMIT_METHOD ? 'Добавить' : 'Обновить'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
