import React, { useCallback, useEffect } from 'react';
import { Button, Form, Input } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';

import styles from './PaymentReference.module.scss';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useHttp, UseHttpI } from '../../../hooks/useHttp';
import { useActions } from '../../../hooks/useActions';
import {
  SelectOptionHelper,
  SelectOptionUserHelper,
} from '../../../helpers/selectHelper';
import { ApiUrl } from '../../../types/ApiUrl';
import NotificationAlert from '../../../components/Notification';
import { entityPaymentReferenceOptions } from '../../../helpers/paymentReferenceHelper';
import FormSelect from '../../../components/FormItems/FormSelect';
import FormInput from '../../../components/FormItems/FormInput';
import { useTrimField } from '../../../hooks/useTrimField';
import { ADD_SUBMIT_METHOD } from '../../../types/constants';

interface PaymentReferenceProps {
  titlePage: string;
  submitMethod: string;
}

export const PaymentReference: React.FC<PaymentReferenceProps> = ({
  titlePage,
  submitMethod,
}) => {
  const { payments } = useTypedSelector((state) => state.payment);
  const { users } = useTypedSelector((state) => state.user);
  const { user } = useTypedSelector((state) => state.auth);

  const { id } = useParams();
  const { request } = useHttp();

  const navigate = useNavigate();

  const { fetchPaymentsForSelect, fetchUsersForSelect } = useActions();

  const paymentsOptions = SelectOptionHelper(payments);
  const usersOptions = SelectOptionUserHelper(users);

  const getData = useCallback(async () => {
    if (id) {
      try {
        const fetchPaymentReference = await request({
          url: `${ApiUrl.GET_ONE_PAYMENT_REFERENCE}/${id}`,
          method: 'get',
        });

        form.setFieldsValue({
          paymentSystemId: fetchPaymentReference.data?.paymentSystemId,
          entity: fetchPaymentReference.data?.entity,
          entityId: fetchPaymentReference.data?.entityId,
          userId: fetchPaymentReference.data?.userId,
          managerId: fetchPaymentReference.data?.managerId,
          phone: fetchPaymentReference.data?.phone,
          email: fetchPaymentReference.data?.email,
          sum: fetchPaymentReference.data?.sum,
          reference: fetchPaymentReference.data?.reference,
        });
      } catch (e) {}
    }
  }, [request]);

  const [form] = Form.useForm();

  useEffect(() => {
    fetchUsersForSelect();
    fetchPaymentsForSelect('all');
    getData();
  }, []);

  const onFinish = async (e: any) => {
    try {
      useTrimField(form, null, e);

      const requestObject: UseHttpI =
        submitMethod === ADD_SUBMIT_METHOD
          ? {
              url: ApiUrl.PAYMENT_REFERENCE,
              method: 'post',
              body: { ...e, managerId: user?.id },
            }
          : {
              url: `${ApiUrl.PAYMENT_REFERENCE}/${id}`,
              method: 'put',
              body: e,
            };

      const response = await request(requestObject);
      form.setFieldValue('reference', response.data?.reference);

      switch (response.config.method) {
        case 'post':
          return NotificationAlert('success', 'Платёж по ссылке добавлен');
        case 'put':
          return NotificationAlert('success', 'Платёж по ссылке обновлен');
        default:
          return NotificationAlert('error', 'Упс');
      }
    } catch (err: any) {
      if (err.response) {
        return err.response.data.map((el: any) => {
          return NotificationAlert('error', el);
        });
      } else {
        return NotificationAlert('error', err.response.data?.message || err.message);
      }
    }
  };

  return (
    <div className={styles.main}>
      <h1>{titlePage}</h1>
      <Form onFinish={onFinish} form={form} labelCol={{ span: 6 }}>
        <FormSelect
          name={'paymentSystemId'}
          label={'Способ оплаты'}
          placeholder={'Способ оплаты'}
          options={paymentsOptions}
          labelCol={{ span: 24 }}
        />
        <FormSelect
          name={'entity'}
          label={'Объект'}
          placeholder={'Объект'}
          required={true}
          options={entityPaymentReferenceOptions}
          labelCol={{ span: 24 }}
        />
        <FormInput
          name={'entityId'}
          label={'Номер заказа/кредита (number)'}
          placeholder={'Номер заказа/кредита'}
          required={true}
          type={'number'}
          labelCol={{ span: 24 }}
        />
        <FormSelect
          name={'userId'}
          label={'Пользователь'}
          placeholder={'Выберите пользователя'}
          showSearch={true}
          options={usersOptions}
          labelCol={{ span: 24 }}
        />
        <FormInput
          name={'phone'}
          label={'Телефон'}
          placeholder={'Телефон'}
          labelCol={{ span: 24 }}
        />
        <FormInput
          name={'email'}
          label={'E-mail'}
          placeholder={'E-mail'}
          labelCol={{ span: 24 }}
        />
        <FormInput
          name={'sum'}
          label={'Сумма (number)'}
          placeholder={'Сумма'}
          required={true}
          type={'number'}
          labelCol={{ span: 24 }}
        />
        <Form.Item
          name={'reference'}
          label={'Ссылка на оплату'}
          labelCol={{ span: 24 }}
        >
          <Input placeholder={'Ссылка'} readOnly></Input>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {submitMethod === ADD_SUBMIT_METHOD ? 'Добавить' : 'Обновить'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
